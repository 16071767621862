import React from 'react';
import { LinkButton } from './LinkButton';

const LINKS = {
  SPOTIFY: 'https://open.spotify.com/playlist/38MOgA6AbUoLkS9pNOqVQt?si=pua_GqpCQqCJmcsivkbuBA&pi=u-JYhNVk8hTnm3',
  DEEZER: 'https://deezer.page.link/K4odsmXewAvbT47b9',
  SUA_MUSICA: 'https://www.suamusica.com.br/aeciolevy/composicoes-de-aecio-levy',
  CONTENT_VAQUEJADA: 'https://www.youtube.com/@aeciolevy',
  CONTACT: 'https://wa.me/14388093393?text=Oi%20A%C3%A9cio%2C%20vim%20do%20link%20do%20Instagram%20e%20queria%20falar%20com%20voc%C3%AA...',
};

const LinkTree = () => {

  return (
    <div className="bg-primary p-8 md:p-16 shadow-md md:h-[calc(100vh-52px)] md:flex md:flex-col md:justify-center">
      <div className="text-center mb-8">
        <img src="/images/eu2.png" alt="Aécio Levy"
          className="w-32 h-32 rounded-full mx-auto border-white border-animation" />
        <h2 className="p-2 text-4xl font-bold text-gray-400">
          <span className="animate-appear-letter"> Aécio Levy </span></h2>
      </div>
      <h2 className="mb-4 text-xl text-headers font-semibold md:text-3xl md:text-center">
        Ouça as músicas que eu compus
      </h2>
      <div className="flex flex-col gap-6 mb-6 md:w-1/2 md:mx-auto">
        <LinkButton link={LINKS.SPOTIFY}>
          <img src="/images/spotify.png" className="w-8 invert" alt="spotify" />
          <span className="mx-auto"> Spotify </span>
        </LinkButton>
        <LinkButton link={LINKS.DEEZER}>
          <img src="/images/deezer.png" className="w-8 invert" alt="deezer" />
          <span className="mx-auto"> Deezer </span>
        </LinkButton>
        <LinkButton link={LINKS.SUA_MUSICA}>
          <img src="/images/suamusica.png" className="w-6 invert" alt="sua música" />
          <span className="mx-auto"> Sua Música </span>
        </LinkButton>
      </div>
      <hr className="border-headers mb-6 md:w-1/2 md:mx-auto" />

      <div className="flex flex-col gap-6 mb-6 md:w-1/2 md:mx-auto">
        <LinkButton link={LINKS.CONTENT_VAQUEJADA}>
          <img src="/images/vaquejada.png" className="w-8 invert" alt="vaquejada" />
          <span className="mx-auto"> Conteúdo de Vaquejada
          </span>
        </LinkButton>
        <LinkButton link={LINKS.CONTACT}>
          <img src="/images/contact.png" className="w-8 invert" alt="contato" />
          <span className="mx-auto"> Fale comigo </span>
        </LinkButton>
      </div>
    </div>
  );
};

export default LinkTree;

