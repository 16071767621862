import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

export const supportedLngs = {
  en: 'English',
  'pt_br': 'Português',
};

const detectionOptions = {
  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // only detect languages that are in the whitelist
  checkWhitelist: true,
};

i18n
  // Add React bindings as a plugin.
  .use(LanguageDetector)
  .use(initReactI18next)
  // Initialize the i18next instance.
  .init({
    // Config options
    detection: detectionOptions,

    supportedLngs: Object.keys(supportedLngs),
    // Enables useful output in the browser’s
    // dev console.
    debug: false,

    interpolation: {
      escapeValue: false,
    },

    resources: {
      // English
      en: {
        // `translation` is the default namespace.
        // More details about namespaces shortly.
        translation: {
          hello_world: "Hello, World!",
        },
      },
      // Arabic
      "pt_br": {
        translation: {
          hello_world: "Olá, Mundo!",
        },
      },
    },
  });

export default i18n;

