import './App.css';
import LinkTree from './LinkTree';
import { LinkTreeEnglish } from './LinkTreeEnglish';
import { useTranslation } from "react-i18next";
import { supportedLngs } from './i18n/config';


function App() {
  const { i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;
  const supportedLanguages = Object.keys(supportedLngs);
  const ENGLISH = 0;
  const PORTUGUESE = 1;

  return (
    <div className="min-h-screen h-lvh">
      <main>
        {activeLocale === supportedLanguages[ENGLISH] && <LinkTreeEnglish />}
        {activeLocale === supportedLanguages[PORTUGUESE] && <LinkTree />}
      </main>
      <footer className="bg-zinc-700 text-center text-white text-sm py-4">
        &copy; aeciolevy 2024
      </footer>
    </div>
  );
}

export default App;

