import React from 'react';
import { LinkButton } from './LinkButton';

const LINKS = {
  LINKEDIN: 'https://www.linkedin.com/in/aeciolevy/',
  CONTACT: 'https://wa.me/14388093393?text=Hi%20A%C3%A9cio,%20I%20came%20from%20your%20link%20tree%20contact..',
};

const LinkTreeEnglish = () => {

  return (
    <div className="bg-primary p-8 md:p-16 shadow-md h-[calc(100vh-52px)] md:flex md:flex-col md:justify-center">
      <div className="text-center mb-8">
        <img src="/images/eu2.png" alt="Aécio Levy"
          className="w-32 h-32 rounded-full mx-auto border-white border-animation" />
      </div>
      <div className="mb-6">
        <h2 className="mb-4 text-xl text-headers font-semibold md:text-3xl text-center">
          Hi, I'm Aécio Levy
        </h2>
        <h2 className="mb-4 text-xl text-gray-400 md:text-2xl md:w-1/2 md:mx-auto">
          I am on a journey to explore skills like sales, learning, marketing, and communication to make a life from them and work always remotely.
        </h2>
      </div>
      <div className="flex flex-col gap-6 mb-6 items-center md:w-1/2 md:mx-auto">
        <LinkButton link={LINKS.LINKEDIN}>
          <img src="/images/linkedin.png" className="w-8 invert" alt="linkedin" />
          <span className="mx-auto"> LinkedIn </span>
        </LinkButton>
        <LinkButton link={LINKS.CONTACT}>
          <img src="/images/contact.png" className="w-8 invert" alt="contact" />
          <span className="mx-auto"> WORK WITH ME </span>
        </LinkButton>
        <div className="opacity-60 w-full" disabled>
          <LinkButton className="pointer-events-none">
            <img src="/images/blog.png" className="w-8 invert" alt="blog" />
            <span className="mx-auto"> BLOG (BUILDING) </span>
          </LinkButton>
        </div>

      </div>
    </div>
  );
};

export { LinkTreeEnglish };

